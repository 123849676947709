// libraries
import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import type { DetectionData } from 'app/MissionControlMethaneSolution/types/detection'

const useDetectionSelect = ({
  detection,
  routeUrl,
  detectionIdRouteParamName = '',
}: {
  detection?: DetectionData
  routeUrl?: string
  detectionIdRouteParamName?: string
}): [
  Partial<DetectionData> | undefined,
  (v?: Partial<DetectionData>) => void
] => {
  const [selectedDetection, setSelectedDetection] = useState<
    Partial<DetectionData> | undefined
  >(detection)
  const navigate = useNavigate()
  const params = useParams()
  const currentDetectionId = params[detectionIdRouteParamName]

  // When user clicks on a detection, or closes a blade
  // change the route to /{pageName}/{detectionId} or back to /{pageName}
  const onDetectionSelected = useCallback(
    (newSelectedDetection?: Partial<DetectionData>) => {
      setSelectedDetection(newSelectedDetection)
      const newDetectionId = newSelectedDetection?.id

      if (routeUrl && currentDetectionId !== newDetectionId) {
        navigate(`${routeUrl}${newDetectionId ? `/${newDetectionId}` : ''}`, {
          replace: true,
        })
      }
    },
    [routeUrl, currentDetectionId, navigate]
  )

  return [selectedDetection, onDetectionSelected]
}

export default useDetectionSelect
