// constants
import { ENTITIES } from 'constants/common'
import { AUDITS_FIELDS, ENTITY_OWNER_FIELDS } from 'constants/graphql'

// utils
import {
  getEntityGraphql,
  listEntitiesGraphql,
  getEntityQuery,
  getEntitiesQuery,
  mutateEntity,
  MutateEntity,
} from 'services/api/utils'
import { getQueryFields } from 'helpers/graphql'

import type { AncillaryData } from 'types/ancillaryData'

const domain = ENTITIES.ancillaryData
const queryDomain = `${domain}s`

const getAncillaryDataFields = getQueryFields({
  id: true,
  name: true,
  type: true,
  isPrivate: true,
  assetType: true,
  assetName: true,
  assetContent: true,
  thumbnailUrl: true,
  ...AUDITS_FIELDS,
  ...ENTITY_OWNER_FIELDS,
})

const getAncillaryDataByIdQuery = getEntityQuery({
  queryDomain,
  getFieldsFn: getAncillaryDataFields,
})

const getAllAncillaryDataQuery = getEntitiesQuery({
  queryDomain,
  getFieldsFn: getAncillaryDataFields,
})

export const getAncillaryData = getEntityGraphql<AncillaryData>({
  queryDomain,
  getQueryFn: getAncillaryDataByIdQuery,
  queryDisplayName: 'GetAncillaryDataById',
})

export const listAncillaryData = listEntitiesGraphql<AncillaryData>({
  queryDomain,
  defaultOmitFields: ['assetContent'],
  getQueryFn: getAllAncillaryDataQuery,
  queryDisplayName: 'GetAllAncillaryData',
})

const mutateAncillaryData = (props: Omit<MutateEntity, 'queryDomain'>) =>
  mutateEntity<AncillaryData>({
    queryDomain,
    responseFields: {
      [domain]: getAncillaryDataFields(),
    },
    responsePath: [domain],
    ...props,
  })

export const createAncillaryData = (
  args: Omit<MutateEntity, 'queryDomain'>
): Promise<AncillaryData> => {
  return mutateAncillaryData({
    fnName: 'createAncillaryData',
    withIdentifier: false,
    variableFormat: 'CreateAncillaryDataInput!',
  })(null, args)
}

export const updateAncillaryData = mutateAncillaryData({
  fnName: 'updateAncillaryData',
  variableFormat: 'UpdateAncillaryDataInput!',
})

export const shareAncillaryData = (id: string): Promise<AncillaryData> => {
  const args = { isPrivate: false }
  return updateAncillaryData(id, args)
}

export const deleteAncillaryData = mutateAncillaryData({
  fnName: 'deleteAncillaryData',
  argsKey: null,
  responseFields: {
    [domain]: getAncillaryDataFields({ pickFields: ['id'] }),
  },
  noReturnData: true,
  postProcessFn: null,
})
