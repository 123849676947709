// libraries
import _ from 'lodash'
import { point as turfPoint } from '@turf/helpers'

// constants
import { USER_ESSENTIAL_FIELDS } from 'services/api/user'
import {
  CORRELATE_EVENT_TYPES,
  DETECTION_STATUS,
  DETECTION_STATUS_LABELS_MAPPING,
  PROGRESS_MAPPING,
  RESOLUTION_MODE_MAPPING,
  WORK_ITEM_LABELS_MAPPING,
} from 'app/MissionControlMethaneSolution/constants/detection'
import {
  DETECTION_TYPES,
  NO_VALUE_PLACEHOLDER,
} from 'app/MissionControlMethaneSolution/constants/common'
import { EMISSION_OBSERVATION_ATTRIBUTION_TYPES } from 'app/MissionControlMethaneSolution/constants/emissionObservation'
import { ISSUE_FOR_EMISSION_OBSERVATION_FRAGMENT } from 'constants/issue'

// utils
import {
  getArgs,
  getFields,
  getInlineFragments,
  getQueryFields,
} from 'helpers/graphql'
import {
  getEntitiesQuery,
  getEntityGraphql,
  getEntityQuery,
  listEntitiesGraphql,
  mutateEntity,
  MutateEntity,
} from 'services/api/utils'
import { getDetectionTypeLabel } from 'app/MissionControlMethaneSolution/helpers/detection'
import {
  getAttributionName,
  isVFBEvent,
} from 'app/MissionControlMethaneSolution/helpers/emissionObservation'
import { getFriendlyDateTimeDuration } from 'helpers/datetime'

import type { Payload } from 'types/common'
import type { EmissionObservation } from 'app/MissionControlMethaneSolution/types/graphql'
import type { EmissionObservationData } from 'app/MissionControlMethaneSolution/types/detection'
import type { QueryParams, SelectedFields } from 'types/services'
import { deserializeSite } from './detection'

export const DOMAIN = 'emissionObservation'
const queryDomain = `${DOMAIN}s`

export const EQUIPMENT_REFERENCE_FIELDS = {
  assetReference: true,
  asset: {
    properties: getArgs({ properties: ['name', 'FLOC'] }),
  },
}

export const VENTING_EVENT_FIELDS_MAPPING = {
  VentingEvent: {
    ventingEventType: true,
    description: true,
    blowdown: true,
    ongoingStatus: true,
    additionalData: true,
  },
}

const clueFields = {
  data: true,
  id: true,
  images: {
    label: true,
    url: true,
  },
  type: true,
}

export const SOURCE_ATTRIBUTION_FIELDS = {
  attributionExplanation: true,
  attributionType: true,
}

const DETECTION_FIELDS_MAPPING = {
  Detection: {
    status: true,
    screeningId: true,
    detectionType: true,
    detectionSource: true,
    sourceDescription: true,
    additionalData: true,
    purpose: true,
    media: {
      downloadUrl: true,
      mediaKey: true,
    },
    component: {
      equipmentComponentName: true,
    },
    resolution: {
      correlatedEmissionObservation: {
        emissionObservation: {
          id: true,
          shortId: true,
          detectedAt: true,
          emissionsRate: {
            value: true,
          },
          sourceAttribution: SOURCE_ATTRIBUTION_FIELDS,
          priority: true,
          ...getInlineFragments({
            Detection: {
              additionalData: true,
            },
          }),
        },
      },
      correlatedClue: {
        clue: _.pick(clueFields, ['id', 'data', 'type']),
      },
      resolutionMode: true,
      explanation: true,
    },
    clues: {
      clueGroups: {
        clues: {
          clue: clueFields,
        },
        type: true,
      },
    },
  },
}

export const CAM_ASSET_REFERENCE_FIELDS = {
  asset: {
    assetReference: true,
    properties: getArgs({
      properties: ['name', 'isDAC', 'FLOC', 'FLOC_NAME', 'id'],
    }),
    group: true,
    geometryJson: true,
  },
}

export const EMISSION_OBSERVATION_FIELDS = {
  acknowledged: true,
  audit: {
    createdBy: {
      user: USER_ESSENTIAL_FIELDS,
    },
    createdTime: true,
    updatedTime: true,
  },
  complianceDeadline: true,
  complianceSeverity: true,
  correlatedEmissionObservationCount: true,
  closed: true,
  detectedAt: true,
  endTime: true,
  id: true,
  shortId: true,
  startTime: true,
  emissionsRate: {
    value: true,
  },
  equipmentExplanation: true,
  camSourceAssetReference: EQUIPMENT_REFERENCE_FIELDS,
  inputType: true,
  priority: true,
  sourceAttribution: SOURCE_ATTRIBUTION_FIELDS,
  camAssetReference: CAM_ASSET_REFERENCE_FIELDS,
  sourceLatitude: true,
  sourceLongitude: true,
  ...getInlineFragments({
    ...DETECTION_FIELDS_MAPPING,
    ...VENTING_EVENT_FIELDS_MAPPING,
  }),
  wip: true,
  siteMonthReference: {
    reconciliationSiteMonth: {
      isClosed: true,
    },
  },
  issues: {
    issue: ISSUE_FOR_EMISSION_OBSERVATION_FRAGMENT,
    issueId: true,
  },
}

export const getEmissionObservationFields = getQueryFields(
  EMISSION_OBSERVATION_FIELDS
)

const COMMON_EMISSION_OBSERVATION_FIELDS = [
  'id',
  'shortId',
  'detectedAt',
  'startTime',
  'endTime',
  'audit.updatedTime',
  'emissionsRate.value',
  'screeningId',
  'inputType',
  'detectionType',
  'priority',
  'camAssetReference.asset.properties',
  'camSourceAssetReference.asset.properties',
  'sourceAttribution',
  'sourceLongitude',
  'sourceLatitude',
  'additionalData',
  'wip',
  'closed',
  '__on[1]',
]

const COMMON_INBOX_EMISSION_OBSERVATION_FIELDS = [
  'acknowledged',
  ...getFields(
    [
      '__typeName',
      'screeningId',
      'detectionType',
      'component.equipmentComponentName',
      'additionalData',
    ],
    '__on[0]'
  ),
  ...COMMON_EMISSION_OBSERVATION_FIELDS,
]

export const COMMON_WIP_DETECTION_FIELDS = [
  'resolution',
  'complianceDeadline',
  'complianceSeverity',
  'correlatedEmissionObservationCount',
  'startTime',
  'endTime',
  ...getFields(
    [
      '__typeName',
      'screeningId',
      'detectionType',
      'component.equipmentComponentName',
      'status',
      'resolution.explanation',
      'resolution.resolutionMode',
    ],
    '__on[0]'
  ),
  ...COMMON_EMISSION_OBSERVATION_FIELDS,
]

const INBOX_DETECTION_VARIABLES = {
  first: 'Int',
  after: 'String',
  sortBy: 'EmissionObservationSortBy',
  filter: 'InboxDetectionFilter',
}

const getEmissionObservationsQuery = (
  queryName: string,
  variables: Payload<string> = INBOX_DETECTION_VARIABLES
) =>
  getEntitiesQuery<EmissionObservation>({
    queryDomain,
    queryName,
    variables,
    getFieldsFn: getEmissionObservationFields,
  })

const INBOX_QUERY_NAME = 'inbox'

export const DETECTION_DEFAULT_OMIT_FIELDS = [
  '__on[0].clues',
  'camAssetReference.asset.geometryJson',
]

const getWorkItem = ({
  wip,
  closed,
  detectionType,
  status,
}: Pick<
  EmissionObservationData,
  'detectionType' | 'status' | 'wip' | 'closed'
>) => {
  if (wip && !closed) {
    return detectionType === DETECTION_TYPES.DETECTION_TYPE_OGI &&
      status === DETECTION_STATUS.DETECTION_STATUS_NEW
      ? 'OGI'
      : _.get(WORK_ITEM_LABELS_MAPPING, status)
  }
  return ''
}

export const getEmissionObservationStatus = (
  emissionObservation: EmissionObservationData
): string => {
  const { closed, resolution, status } = emissionObservation

  const resolutionMode = _.get(resolution, 'resolutionMode')

  const resolutionLabel = _.get(RESOLUTION_MODE_MAPPING, resolutionMode)

  const isVentingEventClosed = isVFBEvent(emissionObservation) && closed

  const result = isVentingEventClosed
    ? 'Closed'
    : status === DETECTION_STATUS.DETECTION_STATUS_RESOLVED
    ? resolutionLabel
    : _.get(DETECTION_STATUS_LABELS_MAPPING, status)

  return result
}

export const deserializeEmissionObservation = (
  emissionObservation: Partial<EmissionObservationData>
): EmissionObservationData | null => {
  if (!emissionObservation) return null

  const {
    camAssetReference,
    camSourceAssetReference,
    resolution,
    emissionsRate,
    complianceDeadline,
    complianceSeverity,
    audit,
    detectionType,
    component,
    priority,
    sourceLatitude,
    sourceLongitude,
    ventingEventType,
    status,
    sourceAttribution,
    startTime,
    endTime,
  } = emissionObservation

  const { asset } = camAssetReference ?? {}
  const { asset: camSourceAssetReferenceAsset } = camSourceAssetReference ?? {}
  const {
    name: equipmentDescription,
    FLOC: equipmentId,
    components,
  } = camSourceAssetReferenceAsset?.properties ?? {}
  const { explanation, correlatedEmissionObservation, correlatedClue } =
    resolution ?? {}
  const { updatedTime } = audit ?? {}
  const { equipmentComponentName } = component || {}

  let emissionObservationType = detectionType as string

  const correlatedDetectionShortId = _.get(
    correlatedEmissionObservation,
    'detection.shortId'
  )

  const correlatedToType = correlatedEmissionObservation
    ? CORRELATE_EVENT_TYPES.WIP
    : !_.isEmpty(correlatedClue)
    ? CORRELATE_EVENT_TYPES.CLUE
    : undefined

  if (ventingEventType) {
    emissionObservationType =
      DETECTION_TYPES.DETECTION_TYPE_VENTING_FLARING_BLOWDOWN
  }

  const deserializedSite = deserializeSite(asset)
  const sourceAttributionName = getAttributionName({
    sourceAttribution,
    site: deserializedSite,
    equipmentDescription,
  })

  const isAttributedToEquipment =
    sourceAttribution?.attributionType ===
    EMISSION_OBSERVATION_ATTRIBUTION_TYPES.EQUIPMENT

  const sourceAttributionFLOC = isAttributedToEquipment
    ? equipmentId
    : deserializedSite.properties.FLOC

  return {
    ...emissionObservation,
    site: deserializedSite,
    emissionsRate: emissionsRate?.value,
    ...(explanation && { explanation }),
    equipment: equipmentDescription ?? equipmentId,
    sourceAttributionName: !sourceAttributionName
      ? undefined
      : sourceAttributionName,
    sourceAttributionFLOC,
    createdTime: audit?.createdTime,
    components,
    ...(equipmentComponentName && { equipmentComponentName }),
    compliance: { complianceSeverity, complianceDeadline },
    lastUpdatedTime: updatedTime,
    correlatedTo: {
      type: correlatedToType,
      correlatedDetectionShortId,
      correlatedClue,
    },
    type: getDetectionTypeLabel(emissionObservationType),
    ...(_.isNumber(sourceLongitude) &&
      _.isNumber(sourceLatitude) && {
        geoJsonFeature: turfPoint([sourceLongitude, sourceLatitude], {
          priority,
        }),
      }),
    workItem: getWorkItem(emissionObservation),
    progress: _.get(PROGRESS_MAPPING, status),
    finding: null,
    duration: getFriendlyDateTimeDuration(startTime, endTime, true),
    enrichedStatus: getEmissionObservationStatus(emissionObservation),
    wip: emissionObservation.wip,
    closed: emissionObservation.closed,
    explanation:
      emissionObservation.resolution?.explanation ??
      emissionObservation.description ??
      NO_VALUE_PLACEHOLDER,
  }
}

const getEmissionObservationByIdQuery = getEntityQuery({
  queryDomain,
  getFieldsFn: getEmissionObservationFields,
})

export const getEmissionObservationById =
  getEntityGraphql<EmissionObservationData>({
    queryDomain,
    getQueryFn: getEmissionObservationByIdQuery,
    queryDisplayName: 'GetEmissionObservationById',
    postProcessFn: deserializeEmissionObservation,
  })

const getEmissionObservationByIdsQuery = getEntityQuery({
  queryDomain,
  queryName: 'byIds',
  variables: { ids: '[ID!]' },
  getFieldsFn: getEmissionObservationFields,
})

/** Although the query returns a list, using 'getEntityGraphql' here to omit 'edges/node' */
export const getEmissionObservationsByIds = getEntityGraphql<
  EmissionObservationData[]
>({
  queryDomain,
  queryName: 'byIds',
  getQueryFn: getEmissionObservationByIdsQuery,
  queryDisplayName: 'GetEmissionObservationByIds',
  postProcessFn: data => data?.map(deserializeEmissionObservation) || [],
})

const GET_EMISSION_OBSERVATIONS_COMMON_PROPS = {
  queryDomain,
  enableLoadMore: false,
  queryDisplayName: 'GetInboxInProgressDetections',
  defaultOmitFields: DETECTION_DEFAULT_OMIT_FIELDS,
  postProcessFn: deserializeEmissionObservation,
}

export const getInboxOpenEmissionObservations =
  listEntitiesGraphql<EmissionObservation>({
    ...GET_EMISSION_OBSERVATIONS_COMMON_PROPS,
    queryName: INBOX_QUERY_NAME,
    getQueryFn: getEmissionObservationsQuery(INBOX_QUERY_NAME),
    queryDisplayName: 'GetInboxOpenEmissionObservations',
    defaultPickFields: COMMON_INBOX_EMISSION_OBSERVATION_FIELDS,
  })

const WIP_QUERY_NAME = 'workInProgress'

const WIP_DETECTION_VARIABLES = {
  ...INBOX_DETECTION_VARIABLES,
  filter: 'WipDetectionFilter',
}

export const getWIPEmissionObservations =
  listEntitiesGraphql<EmissionObservationData>({
    ...GET_EMISSION_OBSERVATIONS_COMMON_PROPS,
    queryName: WIP_QUERY_NAME,
    getQueryFn: getEmissionObservationsQuery(
      WIP_QUERY_NAME,
      WIP_DETECTION_VARIABLES
    ),
    queryDisplayName: 'GetWIPEmissionObservations',
    defaultPickFields: COMMON_WIP_DETECTION_FIELDS,
  })

export const getRelevantEmissionObservations =
  listEntitiesGraphql<EmissionObservationData>({
    ...GET_EMISSION_OBSERVATIONS_COMMON_PROPS,
    queryName: 'relevantToTarget',
    getQueryFn: getEmissionObservationsQuery('relevantToTarget', {
      filter: 'RelevantToTargetFilter',
    }),
    queryDisplayName: 'relevantToTarget',
    defaultPickFields: COMMON_WIP_DETECTION_FIELDS,
  })

const GET_CORRELATED_EMISSION_OBSERVATIONS_QUERY_NAME =
  'byCorrelatedToEmissionObservationId'

export const getCorrelatedEmissionObservations =
  listEntitiesGraphql<EmissionObservationData>({
    queryDomain,
    enableLoadMore: false,
    queryName: GET_CORRELATED_EMISSION_OBSERVATIONS_QUERY_NAME,
    queryDisplayName: 'GetCorrelatedEmissionObservations',
    postProcessFn: deserializeEmissionObservation,
    getQueryFn: getEmissionObservationsQuery(
      GET_CORRELATED_EMISSION_OBSERVATIONS_QUERY_NAME,
      { correlatedToEmissionObservationId: 'ID!' }
    ),
    defaultPickFields: [
      'id',
      'shortId',
      'detectedAt',
      'emissionsRate',
      'priority',
      'sourceLatitude',
      'sourceLongitude',
      'startTime',
      'endTime',
      'sourceAttribution',
      ...getFields(['__typeName', 'status', 'additionalData'], '__on[0]'),
    ],
  })

const mutateEmissionObservation =
  ({
    pickFields,
    omitFields,
    ...restProps
  }: Omit<MutateEntity, 'queryDomain'> & SelectedFields) =>
  ({
    id,
    fieldsWithArguments,
    ...rest
  }: { id?: string; fieldsWithArguments?: QueryParams } & QueryParams) =>
    mutateEntity<EmissionObservationData>({
      queryDomain,
      responseFields: {
        [DOMAIN]: getEmissionObservationFields({ pickFields, omitFields }),
      },
      responsePath: [DOMAIN],
      withIdentifier: false,
      ignoreError: true,
      postProcessFn: deserializeEmissionObservation,
      ...restProps,
    })(id, rest, fieldsWithArguments) as Promise<{
      data: EmissionObservationData
      error?: string
    }>

const ACKNOWLEDGE_EMISSION_OBSERVATION_PAYLOAD = {
  fnName: 'acknowledgeEmissionObservation',
  variableFormat: 'AcknowledgeEmissionObservationInput!',
  withIdentifier: true,
  identifier: 'emissionObservationId',
}

export const acknowledgeEmissionObservation = mutateEmissionObservation({
  ...ACKNOWLEDGE_EMISSION_OBSERVATION_PAYLOAD,
})

const ATTRIBUTION_COMMON_FIELDS = ['__typeName', 'component']

export const attributeEmissionObservationToEquipment =
  mutateEmissionObservation({
    fnName: 'attributeEmissionObservationToEquipment',
    variableFormat: 'AttributeEmissionObservationToEquipmentInput!',
    pickFields: [
      'sourceAttribution',
      'equipmentExplanation',
      'camSourceAssetReference',
      'audit.updatedTime',
      ...getFields(ATTRIBUTION_COMMON_FIELDS, '__on[0]'),
    ],
  })

export const attributeEmissionObservationToSite = mutateEmissionObservation({
  fnName: 'attributeEmissionObservationToSite',
  variableFormat: 'AttributeEmissionObservationToSiteInput!',
  pickFields: [
    'sourceAttribution',
    'equipmentExplanation',
    'audit.updatedTime',
    'camAssetReference.asset.properties',
    'camSourceAssetReference.asset',
    ...getFields(ATTRIBUTION_COMMON_FIELDS, '__on[0]'),
  ],
})

export const requestVfbReport = mutateEmissionObservation({
  fnName: 'markDetectionAsVfbReportRequested',
  responseFields: {
    detection: {
      status: true,
    },
  },
  variableFormat: 'MarkDetectionAsVfbReportRequestedInput!',
})

export const pendDetection = mutateEmissionObservation({
  fnName: 'pendDetection',
  responseFields: {
    detection: {
      status: true,
    },
  },
  variableFormat: 'PendDetectionInput!',
})
