/* eslint-disable no-console */
import { onError } from '@apollo/client/link/error'
import _ from 'lodash'
import log from 'helpers/log'

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  log.error(
    `An error occurred in the following query: \n`,
    _.get(operation, 'query.loc.source.body')
  )

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      return log.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  }

  if (networkError) log.error(`[Network error]: ${networkError}`)
})

export default errorLink
