// libraries
import { useState, ReactElement, useEffect, useMemo } from 'react'
import _ from 'lodash'

// constants
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'
import { OPERATION_OPTIONS } from 'constants/common'

// utils
import { uuidv4 } from 'helpers/common'
import { getDefaultLayerStyle } from 'helpers/layerStyle'
import { updateList } from 'helpers/utils'

// types
import type { Payload, PropertiesMetadata } from 'types/common'
import type { MapLayerStyle } from 'types/map'

// components
import {
  DeleteItemDialog,
  Button,
  Accordion,
  IconButton,
  TitleWithTooltip,
} from 'components/common'
import ColourDot from 'components/map/controls/MapLegend/ColourDot'
import useLayerVisibilityControl from 'components/map/layers/styling/LayerStylingTabs/common/LayerStylingCommonSettings/useLayerVisibilityControl'

import LayerStylingLabelSettings from '../LayerStylingLabelSettings'

// styles
import scss from './index.module.scss'

const DEFAULT_PROPERTY_LABEL_CONFIG = {
  labelProperty: LAYER_VIS_CONFIGS.labelProperty.defaultValue,
  labelSize: LAYER_VIS_CONFIGS.labelSize.defaultValue,
  labelColour: LAYER_VIS_CONFIGS.labelColour.defaultValue,
  labelAnchor: LAYER_VIS_CONFIGS.labelAnchor.defaultValue,
  labelAlignment: LAYER_VIS_CONFIGS.labelAlignment.defaultValue,
  labelOffsetX: LAYER_VIS_CONFIGS.labelOffsetX.defaultValue,
  labelOffsetY: LAYER_VIS_CONFIGS.labelOffsetY.defaultValue,
  opacity: LAYER_VIS_CONFIGS.opacity.defaultValue,
  zoomVisibilityRange: LAYER_VIS_CONFIGS.zoomVisibilityRange.defaultValue,
}

export type PropertyLabelConfig = typeof DEFAULT_PROPERTY_LABEL_CONFIG

export type PropertyLabelsConfig = PropertyLabelConfig[]

const LabelStylingGroup = ({
  style,
  propertyOptions,
  onChange,
  onDelete,
  expanded,
}: {
  style: PropertyLabelConfig & { id: string }
  propertyOptions: PropertiesMetadata
  onChange: (v: Payload) => void
  onDelete: (v: string) => void
  expanded: boolean
}): ReactElement => {
  const [showDeleteItemDialog, setShowDeleteItemDialog] = useState(false)
  const [showRegularItem, setShowRegularItem] = useState(true)

  const [newLabelConfig, setNewLabelConfig] = useState(style)
  const { id, labelProperty, labelColour, zoomVisibilityRange } = newLabelConfig

  const handleChange = (newStyle: Payload) => {
    let newConfig
    setNewLabelConfig(oldConfig => {
      newConfig = { ...oldConfig, ...newStyle }
      return newConfig
    })
    onChange(newConfig)
  }

  const { renderVisibilityControl } = useLayerVisibilityControl({
    zoomVisibilityRange,
    updateStyle: handleChange,
  })

  return (
    <>
      {showRegularItem && (
        <Accordion
          className='list-group-item'
          addOn={
            <IconButton
              icon='IconTrash'
              size={14}
              width={14}
              height={14}
              className='hidden'
              onClick={() => setShowDeleteItemDialog(true)}
            />
          }
          title={
            <div className='d-flex align-items-center'>
              <ColourDot fillColour={labelColour} />
              <div className={`${scss.title} singleLineTruncate`}>
                {labelProperty || '(no property)'}
              </div>
            </div>
          }
          content={
            <>
              <LayerStylingLabelSettings
                style={newLabelConfig}
                updateStyle={handleChange}
                propertyOptions={propertyOptions}
              />
              {renderVisibilityControl()}
            </>
          }
          expanded={expanded}
        />
      )}
      <DeleteItemDialog
        label='Label Config'
        itemId={id}
        showDeleteItemDialog={showDeleteItemDialog}
        setShowDeleteItemDialog={setShowDeleteItemDialog}
        setShowRegularItem={setShowRegularItem}
        onDeleteItem={() => onDelete(id)}
      />
    </>
  )
}

export const getNewPropertyLabelConfig = (): PropertyLabelConfig & {
  id: string
} => ({
  ...DEFAULT_PROPERTY_LABEL_CONFIG,
  id: uuidv4(),
})

const LayerStylingLabelsSettings = ({
  style,
  propertyOptions,
  updateStyle,
}: {
  style: MapLayerStyle
  propertyOptions: PropertiesMetadata
  updateStyle: (v: Payload) => void
}): ReactElement => {
  const { propertyLabels } = useMemo(
    () => getDefaultLayerStyle(style) || {},
    [style]
  )

  const [newPropertyLabels, setNewPropertyLabels] = useState(
    propertyLabels || []
  )

  useEffect(() => {
    setNewPropertyLabels(propertyLabels)
  }, [propertyLabels])

  const [expandedId, setExpandedId] = useState<string>('')

  const onChange = (newConfigs: PropertyLabelsConfig): void => {
    updateStyle({ propertyLabels: newConfigs })
  }

  return (
    <div className='groupOption'>
      <div className='groupOptionTitle'>
        <TitleWithTooltip title='Label' tooltip='Labels based on' />
      </div>
      {_.map(
        newPropertyLabels,
        (propertyLabelConfig: PropertyLabelConfig & { id: string }) => {
          return (
            <LabelStylingGroup
              key={propertyLabelConfig.id}
              style={propertyLabelConfig}
              propertyOptions={propertyOptions}
              expanded={propertyLabelConfig.id === expandedId}
              onChange={(newConfig: Payload) => {
                const newList = updateList(
                  newPropertyLabels,
                  OPERATION_OPTIONS.update,
                  newConfig
                )
                onChange(newList)
              }}
              onDelete={(id: string) => {
                onChange(_.reject(newPropertyLabels, { id }))
              }}
            />
          )
        }
      )}
      <Button
        className='mt-2'
        onClick={() => {
          const newConfig = getNewPropertyLabelConfig()
          setNewPropertyLabels((oldLabels: PropertyLabelsConfig) => [
            ...(oldLabels || []),
            newConfig,
          ])
          const { id } = newConfig
          setExpandedId(id)
        }}
      >
        Add Label
      </Button>
    </div>
  )
}

export default LayerStylingLabelsSettings
