// libraries
import {
  useCallback,
  ReactElement,
  FocusEvent,
  FocusEventHandler,
  useState,
} from 'react'
import _ from 'lodash'
import styled from '@emotion/styled'

// types
import type { FieldWrapperProps } from 'components/common/Form/FieldWrapper'
import type { FieldRenderProps } from 'react-final-form'

// utils
import { sanitizeString } from 'helpers/utils'

// components
import { StyledField } from 'components/common/Form'

// scss
import scss from 'components/common/Form/index.module.scss'

export type TextareaProps = {
  id?: string
  type?: string
  characterLimit?: number
  input: {
    name: string
    value: string
    type: string
    onChange: (v: string) => void
    onBlur: FocusEventHandler<HTMLTextAreaElement>
    onFocus: FocusEventHandler<HTMLTextAreaElement>
  }
  className?: string
  autoComplete?: string
  readOnly?: boolean
  disabled?: boolean
  testId?: string
  placeholder?: string
  multiline?: boolean
  rows?: number
} & Pick<
  FieldWrapperProps,
  | 'label'
  | 'description'
  | 'groupOptionStyle'
  | 'containerClassName'
  | 'childrenContainerClassName'
  | 'preserveSpaceForError'
  | 'inline'
  | 'required'
> &
  FieldRenderProps<string, HTMLElement>

const CharacterCount = styled.span<{ isOverLimit: boolean }>`
  position: absolute;
  top: calc(100% + 5px);
  color: ${({ isOverLimit, theme }) =>
    isOverLimit ? theme.danger : theme['secondary-300']};
  font-size: 10px;
  letter-spacing: 0.2px;
  right: 0;
  transition: color 0.15s ease-in-out;
`

const Textarea = (props: TextareaProps): ReactElement => {
  const {
    className,
    input,
    characterLimit,
    label,
    required,
    groupOptionStyle,
    inline,
    testId,
    description,
    multiline,
    containerClassName,
    childrenContainerClassName,
    preserveSpaceForError,
  } = props

  const inputProps = {
    ..._.pick(props, [
      'id',
      'placeholder',
      'required',
      'type',
      'max',
      'min',
      'readOnly',
      'disabled',
      'autoComplete',
      'rows',
    ]),
    ...input,
    className,
  }

  const { readOnly } = inputProps

  const { onBlur, onChange, onFocus, name } = input

  const [characterCount, setCharacterCount] = useState(0)

  const onTextChange = useCallback(
    (event: FocusEvent<HTMLTextAreaElement>) => {
      onFocus(event)
      const cleanText = sanitizeString(event.target.value, multiline) || ''
      onChange(cleanText)
      onBlur(event)
    },
    [onBlur, onChange, onFocus, multiline]
  )

  const onCharacterChange = useCallback(
    (event: FocusEvent<HTMLTextAreaElement>) => {
      const textValue = event.target.value
      setCharacterCount(textValue.length)
      onChange(textValue)
    },
    [onChange]
  )

  return (
    <StyledField
      name={name}
      groupOptionStyle={groupOptionStyle}
      label={label}
      required={required}
      inline={inline}
      description={description}
      containerClassName={containerClassName}
      childrenContainerClassName={childrenContainerClassName}
      preserveSpaceForError={preserveSpaceForError}
    >
      <textarea
        {...inputProps}
        onBlur={onTextChange}
        onChange={onCharacterChange}
        data-testid={testId}
        style={{ cursor: readOnly ? 'default' : 'text' }}
      />
      {characterLimit && (
        <CharacterCount isOverLimit={characterCount > characterLimit}>
          {characterCount}/{characterLimit}
        </CharacterCount>
      )}
    </StyledField>
  )
}

Textarea.defaultProps = {
  label: '',
  type: 'text',
  className: `form-control ${scss.textarea}`,
  required: false,
  autoComplete: 'on',
  groupOptionStyle: false,
  inline: false,
  testId: 'default-textarea',
}

export default Textarea
