import { Dispatch, SetStateAction } from 'react'
import { map as awaityMap } from 'awaity/esm'
import fetch from 'fetch-retry'
import { uuidv4 } from 'helpers/common'
import _ from 'lodash'

import { showError } from 'helpers/message'
import { FILE_UPLOAD_STATUS } from 'constants/fileUpload'

import type { FileUploadingState } from 'components/common/FileUploader'

const fetchRetry = fetch(global.fetch)

export const getMediaKeyFromFileUrl = (fileUrl: string) => {
  const urlData = new URL(fileUrl)
  const encodedFileName = _.last(urlData.pathname.split('/'))
  return decodeURI(encodedFileName || '')
}

export const uploadFiles = async ({
  files,
  mediaUploadUrls,
  originalFileNamesMap,
  setFileUploadingState,
  findFile,
}: {
  files: File[]
  mediaUploadUrls: string[]
  originalFileNamesMap?: Record<string, string>
  setFileUploadingState?: Dispatch<SetStateAction<FileUploadingState>>
  findFile?: (filesToUpload: File[], fileName: string) => File | undefined
}): Promise<unknown[]> => {
  const errors: unknown[] = []

  // NOTE: Works the same as await Promise.all(...)
  await awaityMap(mediaUploadUrls, async (fileUrl: string) => {
    const fileName = getMediaKeyFromFileUrl(fileUrl)
    // The same order between 'files' and 'mediaUploadUrls' is not guaranteed,
    // so we need to perform a search in the array
    const file =
      findFile?.(files, fileName) || files.find(({ name }) => name === fileName)

    if (file) {
      const updateState = (newState: FileUploadingState) =>
        setFileUploadingState?.(state => ({
          ...state,
          ...newState,
        }))

      const originalFileName = originalFileNamesMap?.[file.name] || file.name

      updateState({ [originalFileName]: FILE_UPLOAD_STATUS.UPLOADING })
      try {
        await fetchRetry(fileUrl, {
          method: 'PUT',
          body: file,
          retries: 3,
          retryDelay: 1000,
        })
        updateState({ [originalFileName]: FILE_UPLOAD_STATUS.SUCCESS })
      } catch (error) {
        updateState({ [originalFileName]: FILE_UPLOAD_STATUS.ERROR })
        errors.push(errors)
        showError(
          `Something went wrong while uploading ${originalFileName}: ${
            (error as Error).message
          }`
        )
      }
    }
  })

  return errors
}

export const getShortId = () => _.last(uuidv4().split('-'))

export const getFileNameAndExtension = (fullFileName: string) => {
  const fileExtMatch = fullFileName.match(/\.[0-9a-z]+$/i)
  const fileExtension = fileExtMatch?.[0] || ''
  const fileName = fullFileName.replace(fileExtension, '')
  return { fileName, fileExtension }
}
