import { useMemo, useCallback, ReactElement } from 'react'
import _ from 'lodash'
import { useSetRecoilState } from 'recoil'

// constants
import {
  ASSET_PROFILE_PRINTABLE_LAYOUT,
  ASSET_PROFILE_GRID_LAYOUT_PROPS,
  ASSET_PROFILE_WIDGET_CONFIGS,
  ASSET_PROFILE_WIDGET_CATEGORIES,
  ASSET_PROFILE_DRAG_WIDGET_KEY,
  ASSET_PROFILE_MEDIA_TYPES,
} from 'constants/assets'
import { THEMES } from 'constants/colour'

// utils
import { selectedAssetProfileWidgetIdState } from 'recoilStore/assetsStore'
import { reportErrors } from 'helpers/log'
import { uuidv4 } from 'helpers/common'

import type { Layout } from 'react-grid-layout'
import type { ID, ThemeType } from 'types/common'
import type { Widget } from 'types/asset'

// components
import * as Icons from 'components/icons'
import * as AssetProfileWidget from 'components/assets/assetsProfile/widgets'
import { GridLayout } from 'components/common'
import BuilderGridLayoutItem from '../Item'

import scss from './index.module.scss'
import 'react-grid-layout/css/styles.css'

const DEFAULT_GRID_DROPPING_ITEM = { i: '__dropping-elem__', h: 4, w: 4 }

const getGridItemLayout = (item: Layout) => _.pick(item, ['x', 'y', 'w', 'h'])

type BuilderProps = {
  onConfigsChange: (val: Widget[]) => void
  configs?: Widget[]
  className?: string
  profileId?: ID
  assetId?: ID
  theme?: ThemeType
  mediaType?: string
}

const Builder = ({
  onConfigsChange: setWidgetsConfigs,
  configs: widgetsConfigs = [],
  className,
  profileId,
  assetId,
  theme = THEMES.dark,
  mediaType,
}: BuilderProps): ReactElement => {
  const isPrintable = useMemo(
    () => mediaType === ASSET_PROFILE_MEDIA_TYPES.PRINTABLE,
    [mediaType]
  )

  const layouts = useMemo(
    () => _.map(widgetsConfigs, ({ id, layout }) => ({ ...layout, i: id })),
    [widgetsConfigs]
  )

  const onSelectWidget = useSetRecoilState(selectedAssetProfileWidgetIdState)

  const printableProps = {
    isDraggable: false,
    isResizable: false,
    onLayoutChange: _.noop,
  }

  const grids = useMemo(() => {
    return _.map(widgetsConfigs, config => {
      const { id, widget, settings, name } = config

      const widgetConfig = ASSET_PROFILE_WIDGET_CONFIGS[widget]
      if (!widgetConfig) reportErrors(`${widget} Widget configs not found`)

      const {
        icon,
        enablePanel = true,
        category,
      } = ASSET_PROFILE_WIDGET_CONFIGS[widget]
      const Icon = _.get(Icons, icon)
      const Component = _.get(AssetProfileWidget, widget)

      const isLayoutWidget = category === ASSET_PROFILE_WIDGET_CATEGORIES.layout
      const displayComponent = assetId || isLayoutWidget

      const editProps = {
        onRemoveWidget: () => {
          setWidgetsConfigs(_.reject(widgetsConfigs, { id }))
        },
        onCloneWidget: (oldId: ID) => {
          const findWidget = _.find(widgetsConfigs, { id: oldId })
          const newId = uuidv4()
          const clonedWidget = {
            ...config,
            ...findWidget,
            id: newId,
          }
          setWidgetsConfigs([...widgetsConfigs, clonedWidget])
        },
      }

      return (
        <div key={id}>
          <BuilderGridLayoutItem
            {...config}
            {...(enablePanel && !isPrintable && { onSelectWidget })}
            id={id}
            isLayoutWidget={isLayoutWidget}
            {...(!isPrintable && editProps)}
            theme={theme}
            isPrintable={isPrintable}
          >
            {displayComponent ? (
              <Component
                assetId={assetId}
                settings={settings}
                name={name}
                theme={theme}
                mediaType={mediaType}
              />
            ) : (
              <div className={scss.icon}>
                <Icon size={68} />
              </div>
            )}
          </BuilderGridLayoutItem>
        </div>
      )
    })
  }, [
    assetId,
    isPrintable,
    mediaType,
    onSelectWidget,
    setWidgetsConfigs,
    theme,
    widgetsConfigs,
  ])

  const onAddNewWidget = useCallback(
    (_layout: Layout[], item: Layout, e: DragEvent) => {
      const { name, widgetName, defaultName } = JSON.parse(
        e.dataTransfer.getData(ASSET_PROFILE_DRAG_WIDGET_KEY)
      )

      const id = uuidv4()
      const newConfig = {
        layout: getGridItemLayout(item),
        name: _.isUndefined(defaultName)
          ? `Untitled ${_.upperFirst(name)}`
          : defaultName,
        widget: widgetName,
        settings: {
          assetProfileId: profileId,
        },
        id,
      }
      setWidgetsConfigs([...widgetsConfigs, newConfig])
    },
    [profileId, setWidgetsConfigs, widgetsConfigs]
  )

  const onLayoutChange = useCallback(
    (layout: Layout[]) => {
      const newLayouts = _.keyBy(layout, 'i')
      const newConfigs = widgetsConfigs.map((config: Widget) => {
        return {
          ...config,
          layout: getGridItemLayout(newLayouts[config.id] || config.layout),
        }
      })
      setWidgetsConfigs(newConfigs)
    },
    [setWidgetsConfigs, widgetsConfigs]
  )

  return (
    <GridLayout
      {...ASSET_PROFILE_GRID_LAYOUT_PROPS}
      {...(isPrintable && printableProps)}
      {...(isPrintable && ASSET_PROFILE_PRINTABLE_LAYOUT)}
      className={className}
      onDrop={onAddNewWidget}
      isDroppable
      onLayoutChange={onLayoutChange}
      droppingItem={DEFAULT_GRID_DROPPING_ITEM}
      layout={layouts}
    >
      {grids}
    </GridLayout>
  )
}

export default Builder
