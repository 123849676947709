import { gql } from '@apollo/client'
import { SessionUserGroupErrorsFragment } from './fragments'

export const SET_SESSION_USER_GROUP = gql`
  mutation ($input: AuthSetSessionUserGroupInput) {
    setSessionUserGroup(input: $input) {
      session {
        userGroup
      }
      errors {
        ...SessionUserGroupErrorsFragment
      }
    }
  }
  ${SessionUserGroupErrorsFragment}
`
