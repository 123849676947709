import {
  useState,
  useContext,
  useCallback,
  createContext,
  ReactElement,
  useMemo,
} from 'react'
import _ from 'lodash'

import { buildAbilityFor } from 'helpers/ability'
import { ACTION_TYPES } from 'constants/user'

import type { MongoAbility } from '@casl/ability'
import type { Subject } from 'types/entity'

export type AbilityContextValue = {
  ability: MongoAbility
  getValidSubject: (subject: Subject) => Subject & { group?: string }
  checkAbility: (type: keyof typeof ACTION_TYPES, subject: Subject) => boolean
}

export const getValidSubject = (
  subject: Subject
): Subject & { group?: string } => {
  const { group, user } = _.get(subject, 'owner') || {}
  return { ...subject, ...user, group }
}

export const AbilityContext = createContext<AbilityContextValue>({
  getValidSubject,
} as AbilityContextValue)

export const AbilityProvider = ({
  ability,
  children,
}: {
  ability?: MongoAbility
  children: ReactElement
}): ReactElement => {
  const [abilityValue] = useState(() => ability || buildAbilityFor())

  const checkAbility = useCallback(
    (type: keyof typeof ACTION_TYPES, subject: Subject): boolean => {
      const newSubject = getValidSubject(subject)
      return abilityValue.can(type, newSubject)
    },
    [abilityValue]
  )

  const value = useMemo(
    () => ({ ability: abilityValue, checkAbility, getValidSubject }),
    [abilityValue, checkAbility]
  )

  return (
    <AbilityContext.Provider value={value}>{children}</AbilityContext.Provider>
  )
}

export const useAbilityStateValue = (): AbilityContextValue =>
  useContext(AbilityContext)
