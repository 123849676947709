// libraries
import { useMemo } from 'react'

// constants
import { ACTION_TYPES } from 'constants/user'
import { ENTITIES } from 'constants/common'

// utils
import { useAbilityStateValue, useAuthStateValue } from 'contexts'

import type { MongoAbility } from '@casl/ability'
import type { User } from 'types/user'
import type { Subject } from 'types/entity'

const useAbility = (
  subject?: Subject
): {
  ability: MongoAbility
  canManage?: boolean
  canCreate?: boolean
  canRead?: boolean
  canUpdate?: boolean
  canDelete?: boolean
} => {
  const { ability, getValidSubject } = useAbilityStateValue()

  const results = useMemo(() => {
    if (!subject) return undefined
    const newSubject = getValidSubject(subject)
    const canManage = ability.can(ACTION_TYPES.manage, newSubject)
    const canCreate = ability.can(ACTION_TYPES.create, newSubject)
    const canRead = ability.can(ACTION_TYPES.read, newSubject)
    const canUpdate = ability.can(ACTION_TYPES.update, newSubject)
    const canDelete = ability.can(ACTION_TYPES.delete, newSubject)
    return { canManage, canCreate, canRead, canUpdate, canDelete }
  }, [ability, getValidSubject, subject])

  return { ...results, ability }
}

export const useRoutesAbility = (): {
  canManageUser: boolean
  canCreateUser: boolean
  canManageAssetProfile: boolean
} => {
  const { currentUser } = useAuthStateValue()

  const { group } = currentUser

  const owner = useMemo(() => ({ group }), [group])

  const { canManage: canManageUser = false, canCreate: canCreateUser } =
    useAbility({
      type: ENTITIES.user,
      owner,
    })

  const { canManage: canManageAssetProfile = false } = useAbility({
    type: ENTITIES.assetProfile,
    owner,
  })

  return { canManageUser, canCreateUser, canManageAssetProfile }
}

export const useUserAbility = ({
  user,
}: {
  user: User
}): ReturnType<typeof useAbility> => {
  return useAbility({ ...user, owner: { group: user.group, user } })
}

export default useAbility
