// constants
import { ENTITY_OWNER_FIELDS, AUDITS_FIELDS } from 'constants/graphql'
import { ENTITIES } from 'constants/common'

// utils
import { getQueryFields } from 'helpers/graphql'
import {
  getEntityGraphql,
  listEntitiesGraphql,
  mutateEntity,
  getEntityQuery,
  getEntitiesQuery,
  MutateEntity,
} from 'services/api/utils'

import type { Map } from 'types/map'

const domain = ENTITIES.map
const queryDomain = `${domain}s`

const getMapFields = getQueryFields({
  id: true,
  isPrivate: true,
  name: true,
  type: true,
  style: true,
  thumbnailUrl: true,
  viewCount: true,
  layers: true,
  selectedDateTimeRange: {
    start: true,
    end: true,
  },
  viewState: true,
  timelineIntervalUnit: true,
  spatialFilterEnabled: true,
  ...ENTITY_OWNER_FIELDS,
  ...AUDITS_FIELDS,
})

const getMapByIdQuery = getEntityQuery({
  queryDomain,
  getFieldsFn: getMapFields,
})

const getMapsQuery = getEntitiesQuery<Map>({
  queryDomain,
  getFieldsFn: getMapFields,
})

export const getMap = getEntityGraphql<Map>({
  queryDomain,
  getQueryFn: getMapByIdQuery,
  queryDisplayName: 'GetMapById',
  defaultOmitFields: ['thumbnailUrl'],
})

export const getMapLayers = getEntityGraphql<Map>({
  queryDomain,
  getQueryFn: getMapByIdQuery,
  queryDisplayName: 'GetMapById',
  pickFields: ['layers'],
})

export const listMaps = listEntitiesGraphql<Map>({
  queryDomain,
  getQueryFn: getMapsQuery,
  queryDisplayName: 'GetAllMaps',
  defaultOmitFields: [
    'viewState',
    'style',
    'layers',
    'selectedDateTimeRange',
    'timelineIntervalUnit',
    'spatialFilterEnabled',
  ],
})

const mutateMap = (props: Omit<MutateEntity, 'queryDomain'>) =>
  mutateEntity<Map>({
    queryDomain,
    responseFields: {
      [domain]: getMapFields({
        omitFields: ['thumbnailUrl', 'layers', 'style'],
      }),
    },
    responsePath: [domain],
    ...props,
  })

export const createMap = mutateMap({
  fnName: 'createMap',
  variableFormat: 'CreateMapInput!',
})

export const updateMap = mutateMap({
  fnName: 'updateMap',
  variableFormat: 'UpdateMapInput!',
})

export const cloneMap = mutateMap({
  fnName: 'cloneMap',
  argsKey: null,
  responseFields: {
    [domain]: getMapFields(),
  },
})

export const recordMapViewed = mutateMap({
  fnName: 'recordMapViewed',
  argsKey: null,
  responseFields: {
    [domain]: getMapFields({ pickFields: ['viewCount'] }),
  },
})

export const deleteMap = mutateMap({
  fnName: 'deleteMap',
  argsKey: null,
  responseFields: {
    [domain]: getMapFields({ pickFields: ['id'] }),
  },
  noReturnData: true,
  postProcessFn: null,
})
