// libraries
import { useCallback } from 'react'
import to from 'await-to-js'
import _ from 'lodash'

// utils
import { showCrudResponseMessage } from 'helpers/message'
import { useAuthStateValue } from 'contexts'
import { updateUser, updateUserPreference } from 'services/api/user'

// constants
import { MESSAGE_STATUS } from 'constants/message'
import { ENTITIES } from 'constants/common'

import type { User } from 'types/user'
import type { Payload } from 'types/common'

const WAIT_MS = 1000

const throttledUpdateUser = _.throttle(updateUser, WAIT_MS)

const throttledUpdateUserPreference = _.throttle(updateUserPreference, WAIT_MS)

const updateUserData =
  ({
    updateFn,
    currentUser,
    setCurrentUser,
  }: {
    updateFn: (username: string, payload: Payload) => Promise<User>
    currentUser: User
    setCurrentUser: React.Dispatch<React.SetStateAction<User>>
  }) =>
  async (payload: Payload, entity: string = ENTITIES.user) => {
    const onlyToastOnErrors = true
    const [error, newUser] = await to(updateFn(currentUser.username, payload))
    showCrudResponseMessage({
      error,
      status: MESSAGE_STATUS.updated,
      entity,
      onlyToastOnErrors,
      subject: newUser,
    })
    if (!error) {
      setCurrentUser(newUser)
    }
  }

/**
 * ! Keep in mind that we store some settings in the user object (e.g. gallery options),
 * so in most cases you might want to avoid putting the whole 'currentUser' object as a dependency for hooks
 */
const useCurrentUser = () => {
  const {
    currentUser,
    setCurrentUser,
    issueSeverityOptions,
    issueAssigneesOptions,
    sessionUserGroup,
  } = useAuthStateValue()

  const updateCurrentUser = useCallback(
    (...props: [Payload, string]) =>
      updateUserData({
        currentUser,
        setCurrentUser,
        updateFn: throttledUpdateUser,
      })(...props),
    [currentUser, setCurrentUser]
  )

  const updateCurrentUserPreference = useCallback(
    (...props: [Payload, string]) => {
      return updateUserData({
        currentUser,
        setCurrentUser,
        updateFn: throttledUpdateUserPreference,
      })(...props)
    },
    [currentUser, setCurrentUser]
  )

  return {
    currentUser,
    updateCurrentUser,
    updateCurrentUserPreference,
    issueSeverityOptions,
    issueAssigneesOptions,
    sessionUserGroup,
  }
}

export default useCurrentUser
