import { ReactElement, useMemo, useState, useRef, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { useToggle, useUpdateEffect } from 'react-use'

// constants
import { BLADE_VARIANT } from 'constants/common'

import type { DualBladeProps, SecondaryBladeContentRendererType } from './types'
import Blade from '../Blade'
import { getBladeWidth } from '../Blade/styles'

const DualBladeGroup = (props: DualBladeProps): ReactElement => {
  const [showSecondaryBlade, toggleShowSecondaryBlade] = useToggle(false)
  const [isPrimaryBladeMounted, setIsPrimaryBladeMounted] = useState(false)
  const [isSecondaryBladeContentMounted, setIsSecondaryBladeContentMounted] =
    useState(false)

  const secondaryBladeRef = useRef(null)

  const {
    renderPrimaryBladeContent,
    position,
    secondaryBladeSize,
    enableTransition = true,
    size,
    show: showPrimaryBlade,
    zIndex,
    ...primaryBladeProps
  } = props

  const commonProps = {
    variant: BLADE_VARIANT.floating,
    position,
    enableTransition,
    zIndex,
  }
  const commonChildProps = { showSecondaryBlade, toggleShowSecondaryBlade }

  // Defines the offset of the primary blade when the secondary blade is shown
  const positionOffset = useMemo(() => {
    if (showSecondaryBlade) {
      const primaryBladeSize = getBladeWidth(secondaryBladeSize)
      return parseInt(primaryBladeSize, 10)
    }

    return 0
  }, [secondaryBladeSize, showSecondaryBlade])

  const SecondaryBladeContentRenderer =
    useCallback<SecondaryBladeContentRendererType>(
      ({ children }) => {
        if (secondaryBladeRef.current) {
          return createPortal(children, secondaryBladeRef.current)
        }
        return null
      },
      // Re-render the content once the portal target is mounted
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isSecondaryBladeContentMounted]
    )

  useUpdateEffect(() => {
    if (!showPrimaryBlade) toggleShowSecondaryBlade(false)
  }, [showPrimaryBlade, toggleShowSecondaryBlade])

  return (
    <>
      {/* Render the secondary blade first, so the primary blade will be on top of it */}
      <Blade
        size={secondaryBladeSize}
        show={showSecondaryBlade}
        enableOverlay={false}
        enableScrollableContent={false}
        customFooter={null}
        customHeader={null}
        onClose={toggleShowSecondaryBlade}
        // Tracking when the content of a blade is mounted an unmounted
        onEnter={() => setIsSecondaryBladeContentMounted(true)}
        onExited={() => setIsSecondaryBladeContentMounted(false)}
        {...commonProps}
      >
        <div className='h-100 d-flex flex-column' ref={secondaryBladeRef} />
      </Blade>

      <Blade
        show={showPrimaryBlade}
        size={size}
        positionOffset={positionOffset}
        // Enabling a transition to the "slide to left/right" effect
        // for the case when the secondary blade appears
        enableOffsetTransition={enableTransition && isPrimaryBladeMounted}
        // Tracking the state of the blade itself
        onEntered={() => setIsPrimaryBladeMounted(true)}
        onExit={() => setIsPrimaryBladeMounted(false)}
        {...commonProps}
        {...primaryBladeProps}
      >
        {renderPrimaryBladeContent({
          ...commonChildProps,
          SecondaryBladeContentRenderer,
        })}
      </Blade>
    </>
  )
}

export default DualBladeGroup
