// libraries
import {
  CSSProperties,
  MouseEventHandler,
  ReactElement,
  ReactNode,
} from 'react'
import styled from '@emotion/styled'
import _ from 'lodash'
import { CSSObject } from '@emotion/react'
import Color from 'color'

// constants
import { BADGE_TYPES } from 'constants/common'

// utils
import { switchcase } from 'helpers/utils'

import type { BadgeType, BrandingColour } from 'types/common'

import { useStaticResourceStateValue } from 'contexts'
import scss from './index.module.scss'

const getBadgeClassName = (badgeType: BadgeType) => {
  return switchcase({
    [BADGE_TYPES.error]: scss.error,
    [BADGE_TYPES.warning]: scss.warning,
    [BADGE_TYPES.success]: scss.success,
    [BADGE_TYPES.infoGrey]: scss.infoGrey,
    [BADGE_TYPES.infoBlue]: scss.infoBlue,
    [BADGE_TYPES.lightGray]: scss.lightGray,
  })(scss.default)(badgeType)
}

const getBadgeBrandingColourKey = (badgeType: BadgeType): BrandingColour => {
  return switchcase({
    [BADGE_TYPES.primary]: 'primary',
  })('')(badgeType)
}

type StyledBadgeProps = {
  backgroundColour?: string
  badgeType: BadgeType
  outline?: boolean
  disabled?: boolean
}

const StyledBadge = styled.span<StyledBadgeProps>`
  ${({ theme, backgroundColour, badgeType, outline, disabled, onClick }) => {
    const brandingColour = getBadgeBrandingColourKey(badgeType)
    const isOutlinePrimary = outline && badgeType === BADGE_TYPES.primary
    const isInteractive = !!onClick
    const styles: CSSObject = {}

    // Apply branding colour or background colour if available
    if (brandingColour || backgroundColour) {
      styles.backgroundColor = theme[brandingColour] || backgroundColour
    }

    // Determine text color based on background color
    if (backgroundColour) {
      styles.color = Color(backgroundColour).isDark() ? '#fff' : '#000'
    }

    // Apply specific styles for outline primary badges
    if (isOutlinePrimary) {
      styles.backgroundColor = theme['primary-100']
      styles.color = theme['primary-500']
      styles.borderColor = theme['primary-300']
    }

    if (disabled) {
      styles.opacity = '0.5'
    }

    if (isInteractive && !outline && !disabled) {
      styles.cursor = 'pointer'
      styles['&:hover'] = {
        backgroundColor: theme['primary-100'],
        color: theme['primary-500'],
        transition: 'all 0.2s ease',
      }
    }

    return styles
  }}
`

export type BadgeProps = {
  className?: string
  content: string | number | ReactNode
  onDismiss?: (v: string | number) => void
  onClick?: MouseEventHandler<HTMLSpanElement>
  style?: CSSProperties
  backgroundColour?: string
  type?: BadgeType
  iconLeft?: string
  iconRight?: string
  outline?: boolean
  disabled?: boolean
}

const Badge = ({
  content = '',
  style,
  className,
  onDismiss,
  onClick,
  backgroundColour,
  type = BADGE_TYPES.default,
  iconLeft,
  iconRight,
  outline,
  disabled,
}: BadgeProps): ReactElement => {
  const { Icons } = useStaticResourceStateValue()
  const IconLeft = _.get(Icons, iconLeft || '')
  const IconRight = _.get(Icons, iconRight || '')

  return (
    <StyledBadge
      className={`${scss.badge} ${className || ''} ${getBadgeClassName(type)} ${
        outline && scss.outline
      }`}
      outline={outline}
      style={style}
      backgroundColour={backgroundColour}
      badgeType={type}
      data-testid='badge'
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      {iconLeft && (
        <span className={scss.iconLeft}>
          <IconLeft />
        </span>
      )}
      {content}
      {iconRight && (
        <span className={scss.iconRight}>
          <IconRight />
        </span>
      )}
      {_.isFunction(onDismiss) && (
        <button
          type='button'
          className={scss.close}
          aria-label='Dismiss'
          data-testid='badge-dismiss'
          {...(onDismiss && {
            onClick: () => onDismiss && onDismiss(content as string | number),
          })}
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      )}
    </StyledBadge>
  )
}

export default Badge
