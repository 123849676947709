import Color from 'color'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import _ from 'lodash'

// constants
import { BLADE_SIZES, BLADE_POSITION, BLADE_VARIANT } from 'constants/common'

// types
import type { BladeSize, BladePosition, BladeVariant } from 'types/common'

// utils
import { REGULAR_BLADE_TRANSITION_TIMEOUT } from '.'

const OVERLAY_Z_INDEX = 1040

export const getBladeWidth = (size: BladeSize): string => {
  const sizeMap: Record<BladeSize, string> = {
    small: '260px',
    default: '320px',
    medium: '400px',
    large: '500px',
    xLarge: '720px',
    modal: '80vw',
  }

  return sizeMap[size] || '320px'
}

const pickBorderRadius = ({
  variant,
  position,
}: {
  variant: BladeVariant
  position: BladePosition
}): string => {
  switch (variant) {
    // Do not display rounded corners from the docked side
    case BLADE_VARIANT.docked: {
      switch (position) {
        case BLADE_POSITION.left:
          return '0 10px 10px 0'
        case BLADE_POSITION.right:
          return '10px 0 0 10px'
        default:
          return '10px'
      }
    }
    // In the floating blade all corners will be rounded
    case BLADE_VARIANT.floating:
    default:
      return '10px'
  }
}

const getVariantStyles = ({
  variant,
  position,
  positionOffset = 0,
  enableOffsetTransition,
  customHeight,
  customMargin = '0',
}: {
  variant: BladeVariant
  position: BladePosition
  positionOffset?: number
  enableOffsetTransition?: boolean
  customHeight?: string
  customMargin?: string
}) => {
  const panelMargin = 16
  const staticStyle = css`
    height: ${customHeight || '100%'};
    margin: ${customMargin};
  `

  switch (variant) {
    case BLADE_VARIANT.docked:
      return staticStyle

    case BLADE_VARIANT.floating:
    default:
      return customHeight
        ? staticStyle
        : // By default the floating blade is positioned absolutely
          css`
            position: absolute;
            ${position}: ${panelMargin + positionOffset}px;
            top: ${panelMargin}px;
            bottom: ${panelMargin}px;
            ${enableOffsetTransition
              ? `transition: ${position} ${REGULAR_BLADE_TRANSITION_TIMEOUT}ms ease;`
              : ''}
          `
  }
}

export const StyledBlade = styled.div<{
  size: BladeSize
  position: BladePosition
  positionOffset?: number
  enableOffsetTransition?: boolean
  variant: BladeVariant
  customHeight?: string
  customMargin?: string
  zIndex?: number
}>`
  width: ${({ size }) => getBladeWidth(size)};
  border-radius: ${({ variant, position }) =>
    pickBorderRadius({ variant, position })};

  ${({ size }) =>
    size === BLADE_SIZES.modal
      ? css`
          min-width: 400px;
          /* Panel z-index should be greater than overlay's */
          z-index: ${OVERLAY_Z_INDEX + 10};
        `
      : ''}

  ${({ zIndex }) => (_.isNumber(zIndex) ? `z-index: ${zIndex};` : '')}

  ${({
    variant,
    position,
    positionOffset,
    enableOffsetTransition,
    customHeight,
    customMargin,
  }) =>
    getVariantStyles({
      variant,
      position,
      positionOffset,
      enableOffsetTransition,
      customHeight,
      customMargin,
    })}
`

export const StyledBladeOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${props => Color(props.theme.primary).darken(0.5).string()};
  opacity: 0.5;
  z-index: ${OVERLAY_Z_INDEX};
`
